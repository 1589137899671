
import { defineComponent } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmPreviewPhone from '@/components/shared/TmPreviewPhone.vue'
import { useBreakpoints } from '@/compositions/breakpoints'

export default defineComponent({
  name: 'PreviewSurveyModal',
  components: { TmPreviewPhone, TmButton, TmModal },
  setup() {
    const { isXsMax, isSmMax } = useBreakpoints()
    const phoneItem = {
      phone: '(776) 123-4567',
      date: 'Today 9:38 AM',
      text: 'Hello Anastasia, message from: Level Up. Gentleman called again, said it is about calling up on a ticket. No one is getting their texts. \nReply “Stop” to opt out.',
    }

    return {
      phoneItem,
      isXsMax,
      isSmMax,
    }
  },
})
